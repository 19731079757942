const routeForgotPassword = () => import(/* webpackChunkName: "auth-forgot-password" */ '@/views/pages/auth/ForgotPassword.vue')
const routeResetPassword = () => import(/* webpackChunkName: "auth-reset-password" */ '@/views/pages/auth/ResetPassword.vue')

export default [
  {
    path: '/',
    name: 'auth-login',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: routeForgotPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: routeResetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/auth/login/azure/callback',
    name: 'auth-login-azure-callback',
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      checkAuthSSO: true,
    },
  },
]
