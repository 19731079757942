<template>
  <div>

    <b-row v-if="!simpleForm">
      <b-col md="12">
        <b-form-group
          :label="$t('Código Postal')"
        >
          <b-input-group
            v-if="view===false"
          >
            <b-form-input
              v-model="localization.codigoPostal"
              v-mask="tokenMaskCodigoPostal"
              autocomplete="off"
              @keyup.enter="getFillLocalizationByCodePostal"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                size="sm"
                @click.stop.prevent="getFillLocalizationByCodePostal"
              >
                {{ $t('Obter morada e mapa') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.codigoPostal || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Distrito')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.distrito"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="distritos"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.distrito"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="onDistritoChanged"
            @change="onDistritoChanged"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div slot="no-options">
              {{ $t('Nenhum distrito') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.distrito, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Concelho')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.concelho"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="concelhos"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.concelho"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="onConcelhoChanged"
            @change="onConcelhoChanged"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div
              v-if="localization.distrito"
              slot="no-options"
            >
              {{ $t('Nenhum concelho') }}
            </div>
            <div
              v-if="!localization.distrito"
              slot="no-options"
            >
              {{ $t('Escolha um distrito') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.concelho, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Freguesia')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.freguesia"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="freguesias"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.freguesia"
            label="desc"
            item-text="desc"
            item-value="id"
            @input="onFreguesiaChanged"
            @change="onFreguesiaChanged"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div
              v-if="localization.concelho"
              slot="no-options"
            >
              {{ $t('Nenhuma freguesia') }}
            </div>
            <div
              v-if="!localization.concelho"
              slot="no-options"
            >
              {{ $t('Escolha um concelho') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.freguesia, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!simpleForm">
      <b-col md="12">
        <b-form-group
          :label="$t('Zona')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.zona"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.zona || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!simpleForm">
      <b-col md="12">
        <b-form-group
          :label="$t('Rua')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.rua"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="ruas"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.rua"
            label="rua"
            item-text="rua"
            item-value="id"
            @input="setRuaManual"
            @change="setRuaManual"
          >
            <template #option="{ rua }">
              {{ rua }}
            </template>
            <div slot="no-options">
              {{ $t('Nenhuma rua') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.rua, 'rua') }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!simpleForm && showRuaManual===true">
      <b-col md="12">
        <b-form-group
          :label="$t('Rua')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.ruaManual"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.ruaManual || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!simpleForm">
      <b-col md="4">
        <b-form-group
          :label="$t('Nº de porta')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.numeroPorta"
            v-mask="['##########']"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.numeroPorta || '-----' }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('Andar')"
        >
          <v-select
            v-if="view===false"
            v-model="localization.andar"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="floors"
            append-to-body
            :calculate-position="withPopper"
            :loading="loader.andar"
            label="desc"
            item-text="desc"
            item-value="id"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
            <div slot="no-options">
              {{ $t('Sem resultados') }}
            </div>
          </v-select>
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ renderObjToTxt(localization.andar, 'desc') }}
          </p>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="$t('Divisão')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.divisao"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.divisao || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!simpleForm">
      <b-col md="12">
        <b-form-group
          :label="$t('Ajuda de morada')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.ajudaMorada"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.ajudaMorada || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!simpleForm">
      <b-col md="12">
        <b-form-group
          :label="$t('Nome de edifício')"
        >
          <b-form-input
            v-if="view===false"
            v-model="localization.nomeEdificio"
            autocomplete="off"
          />
          <p
            v-else
            class="text-primary mb-0"
          >
            {{ localization.nomeEdificio || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import store from '@/store'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { onUnmounted } from '@vue/composition-api'
import localizationModule from '@store-modules/localization/pt'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [showMsgRequest],
  props: {
    fillMapsLatLong: {
      type: Function,
      default: () => {},
    },
    view: {
      type: Boolean,
      default: () => false,
    },
    simpleForm: {
      type: Boolean,
      default: () => false,
    },
    initDataForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loader: {
        distrito: false,
        concelho: false,
        freguesia: false,
        rua: false,
        andar: false,
      },
      localization: {
        codigoPostal: null,
        distrito: null,
        concelho: null,
        freguesia: null,
        zona: null,
        rua: null,
        numeroPorta: null,
        andar: null,
        divisao: null,
        ajudaMorada: null,
        nomeEdificio: null,
        ruaManual: null,
      },
      tokenMaskCodigoPostal: {
        mask: 'XXXX-XXX',
        tokens: {
          X: {
            pattern: /[0-9]/,
            transform(v) {
              return v.toLocaleUpperCase()
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('localization', ['distritos', 'concelhos', 'freguesias', 'ruas', 'floors']),
    showRuaManual() {
      if ((this.localization.rua !== null) && (this.localization.rua !== undefined) && ('id' in this.localization.rua) && (this.localization.rua.id === 'MANUAL')) {
        return true
      }
      return false
    },
  },
  async created() {
    try {
      this.loader.distrito = true
      await store.dispatch('localization/getAllDistritos').then(() => {
        this.loader.distrito = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.loader.distrito = false
      })

      this.loader.andar = true
      await store.dispatch('localization/getAllFloors').then(() => {
        this.loader.andar = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.loader.andar = false
      })

      this.$store.commit('localization/setRuas', [])

      if (this.simpleForm) {
        await this.loadDataForm(this.initDataForm)
      }
    } catch (err) {
      //
    }
  },
  methods: {
    async onDistritoChanged() {
      await this.getConcelhosByDistrito()
      this.emitChangeValue()
    },
    async onConcelhoChanged() {
      await this.getFreguesiasByConcelho()
      this.emitChangeValue()
    },
    async onFreguesiaChanged() {
      await this.getRuasByFreguesia()
      this.emitChangeValue()
    },
    emitChangeValue() {
      this.$emit('change', {
        distrito: this.localization.distrito ? this.localization.distrito.id : undefined,
        concelho: this.localization.concelho ? this.localization.concelho.id : undefined,
        freguesia: this.localization.concelho ? this.localization.freguesia.id : undefined,
      })
    },
    async getConcelhosByDistrito() {
      const self = this

      this.$store.commit('localization/setConcelhos', [])
      this.localization.concelho = null

      this.$store.commit('localization/setFreguesias', [])
      this.localization.freguesia = null

      this.$store.commit('localization/setRuas', [])
      this.localization.rua = null

      try {
        if (this.localization.distrito.id) {
          self.loader.concelho = true
          await store.dispatch('localization/getAllConcelhosByDistrito', {
            distrito: this.localization.distrito.id,
          }).then(() => {
            self.loader.concelho = false
          }).catch(error => {
            self.loader.concelho = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getFreguesiasByConcelho() {
      const self = this

      this.$store.commit('localization/setFreguesias', [])
      this.localization.freguesia = null

      this.$store.commit('localization/setRuas', [])
      this.localization.rua = null

      try {
        if (this.localization.distrito.id && this.localization.concelho.id) {
          self.loader.freguesia = true
          await store.dispatch('localization/getAllFreguesiasByConcelho', {
            distrito: this.localization.distrito.id,
            concelho: this.localization.concelho.id,
          }).then(() => {
            self.loader.freguesia = false
          }).catch(error => {
            self.loader.freguesia = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getRuasByFreguesia() {
      if (this.simpleForm) {
        return
      }
      const self = this

      this.$store.commit('localization/setRuas', [])
      this.localization.rua = null

      try {
        if (this.localization.distrito.id && this.localization.concelho.id && this.localization.freguesia.id) {
          if (this.localization.codigoPostal) {
            self.loader.ruas = true
            await store.dispatch('localization/getAllRuasByFreguesia', {
              distrito: this.localization.distrito.id,
              concelho: this.localization.concelho.id,
              freguesia: this.localization.freguesia.id,
              codigoPostal: this.localization.codigoPostal,
            }).then(() => {
              self.loader.ruas = false
            }).catch(error => {
              self.loader.ruas = false
              self.showMsgErrorRequest(error)
            })
          } else {
            this.localization.freguesia = null
            this.showMsgErrorRequest({ message: this.$t('Preencha o código postal para obter as ruas') })
          }
        } else {
          this.showMsgErrorRequest({ message: this.$t('Preencha os campos da localização') })
        }
      } catch (err) {
        //
      }

      this.setRuaManual()
    },
    async getFillLocalizationByCodePostal() {
      const self = this

      if (this.localization.codigoPostal && (/^\d{4}-\d{3}$/.test(this.localization.codigoPostal))) {
        this.localization.distrito = null

        this.$store.commit('localization/setConcelhos', [])
        this.localization.concelho = null

        this.$store.commit('localization/setFreguesias', [])
        this.localization.freguesia = null

        this.$store.commit('localization/setRuas', [])
        this.localization.rua = null

        try {
          self.$root.$emit('app::loading', true)
          await store.dispatch('localization/fillLocalizationByCodePostal', {
            codigoPostal: this.localization.codigoPostal,
          }).then(res => {
            self.$root.$emit('app::loading', false)

            this.localization.distrito = this.distritos.find(o => o.id === res.distrito)
            this.localization.concelho = this.concelhos.find(o => o.id === res.concelho)
            this.localization.freguesia = this.freguesias.find(o => o.id === res.freguesia)

            if (res.rua) {
              this.localization.rua = this.ruas.find(o => o.id === res.rua)
            }

            this.setRuaManual()
            this.fillMapsLatLong()
          }).catch(error => {
            self.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
        //
        }
      } else {
        this.showMsgErrorRequest({ message: this.$t('Preencha um código postal válido') })
      }
    },
    setRuaManual() {
      if (this.showRuaManual !== true) {
        this.localization.ruaManual = ''
      }
    },
    getNameSearchGoogleMaps() {
      let txtNameSearchGoogleMaps = ''

      if (this.localization.codigoPostal) {
        txtNameSearchGoogleMaps += this.localization.codigoPostal
      }

      try {
        if (this.localization.distrito.desc) {
          txtNameSearchGoogleMaps += ((txtNameSearchGoogleMaps) ? ', ' : '')
          txtNameSearchGoogleMaps += this.localization.distrito.desc
        }
      } catch (err) {
        //
      }

      try {
        if (this.localization.concelho.desc) {
          txtNameSearchGoogleMaps += ((txtNameSearchGoogleMaps) ? ', ' : '')
          txtNameSearchGoogleMaps += this.localization.concelho.desc
        }
      } catch (err) {
        //
      }

      return txtNameSearchGoogleMaps
    },
    withPopper(dropdownList, component, { width }) {
      const oDropdownList = dropdownList
      oDropdownList.style.width = width
      oDropdownList.style.maxHeight = '175px'
      oDropdownList.style.zIndex = '9999'

      const popper = createPopper(component.$refs.toggle, oDropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }],
      })

      return () => popper.destroy()
    },
    async clearForm() {
      try {
        this.localization.codigoPostal = null
        this.localization.distrito = null
        this.localization.concelho = null
        this.localization.freguesia = null
        this.localization.zona = null
        this.localization.rua = null
        this.localization.numeroPorta = null
        this.localization.andar = null
        this.localization.divisao = null
        this.localization.ajudaMorada = null
        this.localization.nomeEdificio = null
        this.localization.ruaManual = null

        store.commit('localization/setConcelhos', [])
        store.commit('localization/setFreguesias', [])
        store.commit('localization/setRuas', [])
      } catch (err) {
        //
      }
    },
    async getDataSaveForm() {
      return new Promise(resolve => {
        let distritoID = ''
        let concelhoID = ''
        let freguesiaID = ''
        let ruaID = ''
        let andarID = ''

        if ((this.localization.distrito !== undefined) && (this.localization.distrito !== null)) {
          if ('id' in this.localization.distrito) {
            distritoID = this.localization.distrito.id
          }
        }

        if ((this.localization.concelho !== undefined) && (this.localization.concelho !== null)) {
          if ('id' in this.localization.concelho) {
            concelhoID = this.localization.concelho.id
          }
        }

        if ((this.localization.freguesia !== undefined) && (this.localization.freguesia !== null)) {
          if ('id' in this.localization.freguesia) {
            freguesiaID = this.localization.freguesia.id
          }
        }

        if ((this.localization.rua !== undefined) && (this.localization.rua !== null)) {
          if ('id' in this.localization.rua) {
            ruaID = this.localization.rua.id
          }
        }

        if ((this.localization.andar !== undefined) && (this.localization.andar !== null)) {
          if ('id' in this.localization.andar) {
            andarID = this.localization.andar.id
          }
        }

        resolve({
          codigoPostal: this.localization.codigoPostal,
          distrito: distritoID,
          concelho: concelhoID,
          freguesia: freguesiaID,
          zona: this.localization.zona,
          rua: ruaID,
          numeroPorta: this.localization.numeroPorta,
          andar: andarID,
          divisao: this.localization.divisao,
          ajudaMorada: this.localization.ajudaMorada,
          nomeEdificio: this.localization.nomeEdificio,
          ruaManual: this.localization.ruaManual,
        })
      })
    },
    async loadDataForm(payload) {
      try {
        this.localization.codigoPostal = payload.codigoPostal || null

        const newItemDistrito = this.distritos.find(o => Number(o.id) === Number(payload.distrito || 0))
        if (newItemDistrito !== undefined) {
          this.localization.distrito = newItemDistrito
          await this.getConcelhosByDistrito(newItemDistrito)
        }

        const newItemConcelho = this.concelhos.find(o => Number(o.id) === Number(payload.concelho || 0))
        if (newItemConcelho !== undefined) {
          this.localization.concelho = newItemConcelho
          await this.getFreguesiasByConcelho(newItemConcelho)
        }

        const newItemFreguesia = this.freguesias.find(o => o.id === payload.freguesia)
        if (newItemFreguesia !== undefined) {
          this.localization.freguesia = newItemFreguesia
          await this.getRuasByFreguesia(newItemFreguesia)
        }

        this.localization.zona = payload.zona || null
        this.localization.numeroPorta = payload.numeroPorta || null
        this.localization.andar = this.floors.find(o => Number(o.id) === Number(payload.andar || 0))
        this.localization.divisao = payload.divisao || null
        this.localization.ajudaMorada = payload.ajudaMorada || null
        this.localization.nomeEdificio = payload.nomeEdificio || null

        if (!payload.ruaManual) {
          this.localization.rua = this.ruas.find(o => Number(o.id) === Number(payload.rua || 0))
        } else {
          this.localization.rua = this.ruas.find(o => o.id === 'MANUAL')
          this.localization.ruaManual = payload.ruaManual || null
        }
      } catch (err) {
        //
      }
    },
    renderObjToTxt(value, field) {
      let txt = value
      if ((value !== null) && (value !== undefined) && (field in value)) {
        txt = value[field]
      }

      return txt || '-----'
    },
  },
  setup() {
    const LOCALIZATION_MODULE_NAME = 'localization'

    if (!store.hasModule(LOCALIZATION_MODULE_NAME)) {
      store.registerModule(LOCALIZATION_MODULE_NAME, localizationModule)

      onUnmounted(() => {
        if (store.hasModule(LOCALIZATION_MODULE_NAME)) store.unregisterModule(LOCALIZATION_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>
