<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <modal-auth-login
      v-if="enableModalAuthLogin===true"
      ref="modalAuthLogin"
      :resolve="resolveModalAuthLogin"
    />

    <modal-import-leads-external
      v-if="showModalImportLeads.value===true"
      ref="modalImportLeadsExternal"
    />

    <loading
      v-if="enableLoadingApp===true"
      :config="configLoadingApp"
    />

  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import ModalAuthLogin from '@core-custom/components/modal-auth-login/ModalAuthLogin.vue'
import loading from '@core-custom/components/loading/loading.vue'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch, ref, computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import useCapacitor from '@core-custom/utils/capacitor'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import ModalImportLeadsExternal from '@/views/modules/components/modalImportLeads/ModalImportLeads.vue'
// import { Keyboard } from '@capacitor/keyboard'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    ModalAuthLogin,
    loading,

    ModalImportLeadsExternal,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    infoUserAuth() {
      return this.$store.getters['auth/currentUser']
    },
  },
  watch: {
    $route(to) {
      this.$root.$emit('app::callModalImportLeads', to.name)
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    // Eventos criados por Ricardo Torres
    // Evento para verificar sessão do utilizador
    this.$root.$on('app::showModalAuthLogin', resolve => {
      const userSessionAuth = store.getters['auth/currentUserSession']
      if ((userSessionAuth !== null) && (userSessionAuth !== undefined) && ('email' in userSessionAuth)) {
        if (userSessionAuth.email !== '') {
          const appLoading = document.getElementById('loading-bg')
          if (appLoading) {
            appLoading.style.display = 'none'
          }

          this.resolveModalAuthLogin = resolve
          this.enableModalAuthLogin = true
        } else {
          this.$router.push({ name: 'auth-login' })
        }
      } else {
        this.$router.push({ name: 'auth-login' })
      }
    })

    this.$root.$on('app::hideModalAuthLogin', () => {
      this.resolveModalAuthLogin = null
      this.enableModalAuthLogin = false
    })

    // Evento para mostrar loading geral
    this.$root.$on('app::loading', params => {
      if (typeof params === 'object') {
        this.enableLoadingApp = params.status
        this.configLoadingApp = params || {}
      } else {
        this.enableLoadingApp = params
        this.configLoadingApp = {}
      }
    })

    this.$root.$on('app::redirectRouter', params => {
      this.notificationRedirectRouter = params
      localStorage.setItem('router-default', JSON.stringify(params))
    })

    if (localStorage.getItem('newVersion') !== null) {
      localStorage.removeItem('newVersion')
    }

    if (useCapacitor.isNative() === true) {
      useCapacitor.initNotificationDevice()
      useCapacitor.lockScreen()
    }

    useCapacitor.initApp()
    useCapacitor.initNetwork()
  },
  mounted() {
    this.$root.$on('app::callModalImportLeads', routeName => {
      const authUser = this.$store.getters['auth/currentUser']
      this.bModalImportLeads = false
      if (routeName !== 'update-version' && (authUser !== null) && (authUser !== undefined) && ('auth' in authUser) && (authUser.auth === true)) {
        this.$store.dispatch('geral/getTotalLeadsImport').then(res => {
          if (res.total > 0) {
            this.bModalImportLeads = true
            this.$nextTick()
          }
        })
      }
    })
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const enableModalAuthLogin = false
    const resolveModalAuthLogin = null
    const enableLoadingApp = false
    const configLoadingApp = {}

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    // Obter informação do dispositivo
    if (useCapacitor.isNative() === true) {
      store.commit('capacitor/setIsNativeApp', true)
      document.body.classList.add('capacitor')
    } else {
      store.commit('capacitor/setIsNativeApp', false)
    }

    const oInfoDevice = useCapacitor.getDeviceInfo()
    store.commit('capacitor/setInfoDevice', oInfoDevice)

    const idDevice = useCapacitor.getDeviceID()
    store.commit('capacitor/setDeviceID', idDevice)

    const localeDevice = useCapacitor.getDeviceLocale()
    store.commit('capacitor/setDeviceLocale', localeDevice)

    const notificationRedirectRouter = ref({})

    const { route, router } = useRouter()

    watch(notificationRedirectRouter, val => {
      if ((val !== null) && (val !== undefined) && (('name' in val) || ('path' in val)) && (route.value.name !== null)) {
        if ((localStorage.getItem('router-default') !== null) && (localStorage.getItem('router-default') !== '')) {
          localStorage.removeItem('router-default')
        }

        router.push(val).catch(() => {})
      }
    })

    window.addEventListener('orientationchange', () => {
      switch (window.orientation) {
        case 0:
        case 180:
          store.commit('capacitor/setOrientationDevice', 'portrait')
          break
        case -90:
        case 90:
          store.commit('capacitor/setOrientationDevice', 'landscape')
          break
        default:
          store.commit('capacitor/setOrientationDevice', 'portrait')
          break
      }
    })

    if (window.matchMedia('(orientation: portrait)').matches) {
      store.commit('capacitor/setOrientationDevice', 'portrait')
    }

    if (window.matchMedia('(orientation: landscape)').matches) {
      store.commit('capacitor/setOrientationDevice', 'landscape')
    }

    const bModalImportLeads = ref(false)
    const showModalImportLeads = computed(() => bModalImportLeads)

    // const ModalImportLeadsExternal = () => import(/* webpackChunkName: "modal-import-leads" */ /* webpackPrefetch: true */ '@/views/modules/components/modalImportLeads/ModalImportLeads.vue')

    return {
      skinClasses,
      enableScrollToTop,
      enableModalAuthLogin,
      resolveModalAuthLogin,
      enableLoadingApp,
      configLoadingApp,
      showModalImportLeads,
      bModalImportLeads,

      notificationRedirectRouter,
    }
  },
}
</script>
