import axios from 'axios'
import i18n from '@/libs/i18n'
import AzureService from '@core-custom/utils/azureSSO/service'
import apiDefaultConfig from './apiConfig'

export default class ApiService {
  axiosIns = null

  jwtConfig = { ...apiDefaultConfig }

  isAlreadyFetchingAccessToken = false

  subscribers = []

  skipRrefreshToken = 0

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.axiosIns.interceptors.request.use(
      config => {
        if (window.oVue !== undefined) {
          const source = axios.CancelToken.source()
          // eslint-disable-next-line no-param-reassign
          config.cancelToken = source.token

          // Nao adiciona os urls das rotas do refresh token e informação da conta
          if (config.url.includes('/users/refresh-token') || config.url.includes('/users/getInfo')) {
            //
          } else {
            window.oVue.$store.commit('request/addCancelToken', { token: source, url: config.url })
          }
        }

        const accessToken = this.getToken()
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        const serviceToken = this.getServiceToken()
        if (serviceToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.ServiceAuthorization = `${serviceToken}`
        }

        const deviceIsNative = process.env?.VUE_APP_DEVICE_IS_NATIVE || 'false'
        if (deviceIsNative) {
          // eslint-disable-next-line no-param-reassign
          config.headers.common.isNative = deviceIsNative
        }

        // eslint-disable-next-line no-param-reassign
        config.headers.Accept = 'application/json'
        // eslint-disable-next-line no-param-reassign
        config.headers.common.AppSource = 'ZomeMobile'
        // eslint-disable-next-line no-param-reassign
        config.headers.common.AppLang = i18n.locale || ''
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => response,
      async error => {
        const { config, response } = error
        const originalRequest = config
        const self = this

        if (config === undefined) {
          return Promise.reject(new Error(''))
        }

        if (config.url.includes('/users/refresh-token')) {
          return new Promise((resolve, reject) => {
            const serviceToken = self.getServiceToken()

            self.setToken('')
            self.setRefreshToken('')

            if (
              response?.data?.accessToken !== undefined
              && response?.data?.refreshToken !== undefined
              && response.data.accessToken !== ''
              && response.data.refreshToken !== ''
            ) {
              resolve(response)
            } else if (serviceToken === 'sso') {
              self.setServiceToken('')
              reject(new Error(response?.data?.message || 'Token invalid'))
            } else if (typeof window.oVue !== 'undefined' && window.oVue !== null) {
              window.oVue.$root.$emit('app::showModalAuthLogin', resolve)
            }
          })
        }

        if (response && response.status === 401 && !self.isAlreadyFetchingAccessToken) {
          self.isAlreadyFetchingAccessToken = true
          let resAzureService = {
            access_token: '',
            refresh_token: self.getRefreshToken(),
          }

          self.setIdToken('')

          if (
            (self.getServiceToken() === 'sso')
            && ((config?.headers?.isNative !== undefined) && (config?.headers?.isNative === 'true'))
          ) {
            const oAzureService = new AzureService(self.jwtConfig.configs || {}, { userEmail: '' })
            resAzureService = await oAzureService.refreshToken(self.getRefreshToken())

            self.setIdToken(resAzureService?.id_token || '')
          }

          self.refreshToken(resAzureService?.access_token || '', resAzureService?.refresh_token || '').then(r => {
            self.isAlreadyFetchingAccessToken = false

            let sAccessToken = r?.data?.accessToken || ''
            let sRefreshToken = r?.data?.refreshToken || ''
            let sServiceToke = r?.data?.serviceToken || ''

            if (
              r?.accessToken !== undefined
              && r?.refreshToken !== undefined
              && r?.serviceToken !== undefined
              && r.accessToken !== ''
              && r.refreshToken !== ''
            ) {
              sAccessToken = r?.accessToken || ''
              sRefreshToken = r?.refreshToken || ''
              sServiceToke = r?.serviceToken || ''
            }

            self.setToken(sAccessToken)
            self.setRefreshToken(sRefreshToken)
            self.setServiceToken(sServiceToke)
            self.onAccessTokenFetched(sAccessToken)

            if (typeof window.oVue !== 'undefined' && window.oVue !== null) {
              window.oVue.$root.$emit('app::hideModalAuthLogin')
            }
          })
            .catch(() => {
              if (typeof window.oVue !== 'undefined' && window.oVue !== null) {
                window.oVue.$root.$emit('app::redirectRouter', { path: '/' })
              }
            })

          return new Promise(resolve => {
            this.addSubscriber(accessToken => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getServiceToken() {
    return localStorage.getItem(this.jwtConfig.storageServiceKeyName)
  }

  getIdToken() {
    return localStorage.getItem(this.jwtConfig.storageIdKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setServiceToken(value) {
    localStorage.setItem(this.jwtConfig.storageServiceKeyName, value)
  }

  setIdToken(value) {
    localStorage.setItem(this.jwtConfig.storageIdKeyName, value)
  }

  destroyToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageServiceKeyName)
    localStorage.removeItem(this.jwtConfig.storageIdKeyName)
  }

  refreshToken(acessToken, refreshToken) {
    return this.axiosIns.post(`${this.jwtConfig.configs.urlAPP}users/refresh-token`, {
      acessToken,
      refreshToken,
    })
  }

  refreshConfigs(configs) {
    this.jwtConfig.configs = configs || {}
  }

  query(resource, params) {
    return this.axiosIns.get(resource, params).catch(error => Promise.reject(error))
  }

  get(resource) {
    return this.axiosIns.get(`${resource}`).catch(error => Promise.reject(error))
  }

  post(resource, params) {
    return this.axiosIns.post(`${resource}`, params).catch(error => Promise.reject(error))
  }

  update(resource, params) {
    return this.axiosIns.put(`${resource}`, params).catch(error => Promise.reject(error))
  }

  put(resource, params) {
    return this.axiosIns.put(`${resource}`, params).catch(error => Promise.reject(error))
  }

  delete(resource) {
    return this.axiosIns.delete(resource).catch(error => Promise.reject(error))
  }
}
