export default {
  url_base_api: process.env.VUE_APP_ROOT_API,
  tokenType: 'Bearer',
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageServiceKeyName: 'serviceToken',
  storageIdKeyName: 'idToken',
  showModalLogin: 'showModalLogin',
  configs: {},
}
