import { OAuth2Client } from '@byteowls/capacitor-oauth2'

export default class AzureService {
  configServer = {}

  params = {}

  constructor(config, params) {
    this.configServer = config
    this.params = params
  }

  configServiceSSO() {
    const { configServer } = this
    const { params, userEmail } = this.params || {}
    const { oauth2state } = params || {}

    if ((configServer?.configServices?.sso?.active !== undefined) && (configServer.configServices.sso.active === true)) {
      let sAuthorizationBaseUrl = ''
      if (configServer?.configServices?.sso?.config?.urlAuthorize !== undefined) {
        sAuthorizationBaseUrl = configServer.configServices.sso.config.urlAuthorize
        sAuthorizationBaseUrl = sAuthorizationBaseUrl.replace('{tenant_id}', process.env?.VUE_APP_AZURE_AD_TENANT_ID || '')
      }

      let sAccessTokenEndpoint = ''
      if (configServer?.configServices?.sso?.config?.urlAccessToken !== undefined) {
        sAccessTokenEndpoint = configServer.configServices.sso.config.urlAccessToken
        sAccessTokenEndpoint = sAccessTokenEndpoint.replace('{tenant_id}', process.env?.VUE_APP_AZURE_AD_TENANT_ID || '')
      }

      return {
        appId: process.env.VUE_APP_AZURE_AD_CLIENT_ID || '',
        authorizationBaseUrl: sAuthorizationBaseUrl,
        accessTokenEndpoint: sAccessTokenEndpoint,
        scope: configServer?.configServices?.sso?.config?.scopes || '',
        responseType: 'code',
        logsEnabled: false,
        additionalParameters: {
          login_hint: userEmail,
          prompt: 'select_account',
        },
        web: {
          pkceEnabled: false,
          state: oauth2state || '',
          windowOptions: '',
          windowTarget: '_self',
          accessTokenEndpoint: '',
          redirectUrl: `${process.env.VUE_APP_URL_APP}/auth/login/azure/callback`,
        },
        android: {
          pkceEnabled: true,
          redirectUrl: `msauth://${configServer?.configServices?.sso?.config?.packageName || ''}/2jmj7l5rSw0yVb%2FvlWAYkK%2FYBwk%3D`,
        },
        ios: {
          pkceEnabled: true,
          redirectUrl: `msauth.${configServer?.configServices?.sso?.config?.packageName || ''}://auth`,
        },
      }
    }

    return {}
  }

  nameServiceSSO() {
    const { configServer } = this

    if ((configServer?.configServices?.sso?.active !== undefined) && (configServer.configServices.sso.active === true)) {
      return configServer?.configServices?.sso?.name || ''
    }
    return ''
  }

  async authenticate() {
    return OAuth2Client.authenticate(this.configServiceSSO())
  }

  async logout(tokenId) {
    if (this.configServiceSSO()?.appId !== undefined && this.configServiceSSO().appId !== '') {
      await OAuth2Client.logout(
        this.configServiceSSO(),
        tokenId,
      ).then(() => {
      }).catch(() => {
      })
    }
  }

  async refreshToken(refreshToken) {
    return OAuth2Client.refreshToken(
      {
        appId: this.configServiceSSO().appId,
        accessTokenEndpoint: this.configServiceSSO().accessTokenEndpoint,
        scope: this.configServiceSSO().scope,
        refreshToken,
      },
    )
  }
}
