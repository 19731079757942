import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'
import { renderInfoExtraDataRow } from './helper'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewData: state => state.listviewData.value,
    data: state => ({
      init: state.init.value,
      loading: state.loading.value,
      perPage: state.perPage.value,
      totalRows: state.totalRows.value,
    }),
    txt: state => ({
      loading: state.txtLoading,
      default: state.txtDefault,
      notFind: state.txtNotFind,
    }),
    form_data: state => state.form_data.value,
    infoLead: state => state.infoLead.value,
    listingsBSM: state => state.listingsBSM.value,
    urlImgListing: state => state.urlImgListing.value,
    urlImgMarketListing: state => state.urlImgMarketListing.value,
    historyKeyStorage: state => state.historyKeyStorage,
    totalLeads: state => state.totalLeads.value,
    types: state => state.types.value,
    typologies: state => state.typologies.value,
    selectedType: state => state.selectedType.value,
    selectedTypology: state => state.selectedTypology.value,
    listingLeadsCount: state => state.listingLeadsCount.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setLoadingListview(state, payload) {
      state.loading.value = payload
    },
    setListviewInit(state, payload) {
      state.init.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setInfoLead(state, payload) {
      state.infoLead.value = payload
      if (payload.sw453s10 in state.types.value) {
        state.selectedType.value = state.types.value[payload.sw453s10]
      }
      if (payload.sw453s11 in state.typologies.value) {
        state.selectedTypology.value = state.typologies.value[payload.sw453s11]
      }
    },
    setListingsBSM(state, payload) {
      state.listingsBSM.value = payload
    },
    setUrlImgListing(state, payload) {
      state.urlImgListing.value = payload
    },
    setUrlImgMarketListing(state, payload) {
      state.urlImgMarketListing.value = payload
    },
    setTotalLeads(state, payload) {
      state.totalLeads.value = payload
    },
    setUpdateFormData(state, payload) {
      if (payload === null || payload === '') {
        state.form_data.value = {}
      } else {
        state.form_data.value = { ...payload }
      }
    },
    setUpdateFormDataField(state, payload) {
      try {
        if (payload.field) {
          state.form_data.value[payload.field] = payload.value || ''
        }
      } catch (err) {
        //
      }
    },
    setTypes(state, payload) {
      state.types.value = payload
      state.selectedType.value = Object.values(payload).shift()
    },
    setTypologies(state, payload) {
      state.typologies.value = payload
      state.selectedTypology.value = Object.values(payload).shift()
    },
    setSelectedType(state, payload) {
      state.selectedType.value = payload
    },
    setSelectedTypology(state, payload) {
      state.selectedTypology.value = payload
    },
    setLocalization(state, payload) {
      state.infoLead.value.sw453s12 = payload.sw453s12
      state.infoLead.value.sw453s13 = payload.sw453s13
      state.infoLead.value.sw453s14 = payload.sw453s14
    },
    setListingLeadsCount(state, payload) {
      state.listingLeadsCount.value = payload
    },
  },
  actions: {
    async loadLeadsImport({ state, commit, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/loadlist', { root: true })

      const request = await new Promise((resolve, reject) => {
        commit('setListviewInit', 1)

        const formData = new FormData()

        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))
          stateCustom.forEach(field => {
            formData.append('sw453_skip_leads[]', field)
          })
        }

        api.post(`${apiConfig.url_base_api}leads/importacao/loadlist/${state.currentPage.value}`, formData)
          .then(response => {
            try {
              if (response.data !== undefined) {
                if ('leads' in response.data) {
                  commit('setListviewData', state.listviewData.value.concat(response.data.leads))
                }

                commit('setTotalRows', Number(response.data.total))
                commit('setTotalLeads', Number(response.data.totalLeads))
                commit('setLoadingListview', false)
                commit('setListviewInit', 0)

                let statusFinishData = false
                if (Number(response.data.total) < Number(state.perPage.value)) {
                  statusFinishData = true
                }
                if (response.data.leads.length === 0) {
                  statusFinishData = true
                }

                resolve({ finish: statusFinishData })
              } else {
                reject(new Error(i18n.t('Problema ao carregar as Leads')))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar as Leads')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as Leads')))
            }
          })
      })

      return request
    },

    async getInfoLead({ state, dispatch, commit }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/getInfoLead', { root: true })

      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw453s01', btoa(payload.sw453s01) || '')

        api.post(`${apiConfig.url_base_api}leads/importacao/getInfoLead`, formData)
          .then(response => {
            try {
              if (!payload.checkButtons) {
                commit('setUpdateFormData', null)
              }
              if (response.data.error !== undefined && response.data.error !== 1) {
                const {
                  sw453s04, sw453s05, sw453s06, sw453s07,
                } = state.form_data.value

                commit('setInfoLead', response.data.sw453)
                commit('setListingsBSM', response.data.listingsBSM)
                commit('setUrlImgListing', response.data.imagesWebListings)
                commit('setUrlImgMarketListing', response.data.urlImgMarket)
                commit('setTypes', { ...response.data.tiposImoveis.Comercial, ...response.data.tiposImoveis.Habitacional, ...response.data.tiposImoveis.Misto })
                commit('setTypologies', response.data.tipologias.reduce((a, v) => ({ ...a, [v.sw005s01]: v }), {}))
                commit('setListingLeadsCount', response.data.listingLeadsCount)

                if (typeof state.infoLead.value.seguimento !== 'undefined') {
                  const oExtra = renderInfoExtraDataRow(state, state.infoLead.value.seguimento[0])
                  state.infoLead.value.seguimento[0].extra = oExtra || {}
                }

                if (!payload.checkButtons) {
                  commit('setUpdateFormDataField', { field: 'sw453s04', value: response.data.sw453.sw453s04 })
                  commit('setUpdateFormDataField', { field: 'sw453s05', value: response.data.sw453.sw453s05 })
                  commit('setUpdateFormDataField', { field: 'sw453s06', value: response.data.sw453.sw453s06 })
                  commit('setUpdateFormDataField', { field: 'sw453s07', value: response.data.sw453.sw453s07 })
                } else {
                  commit('setUpdateFormDataField', { field: 'sw453s04', value: sw453s04 })
                  commit('setUpdateFormDataField', { field: 'sw453s05', value: sw453s05 })
                  commit('setUpdateFormDataField', { field: 'sw453s06', value: sw453s06 })
                  commit('setUpdateFormDataField', { field: 'sw453s07', value: sw453s07 })
                }
                resolve(true)
              } else {
                reject(new Error(response.data.msg))
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar a informação da Lead')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar a informação da Lead')))
            }
          })
      })

      return request
    },

    async updateLead({ state, dispatch }, payload) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/updateLead', { root: true })

      const request = await new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('sw453s01', btoa(payload.sw453s01) || '')
        formData.append('action', payload.action || '')

        api.post(`${apiConfig.url_base_api}leads/importacao/updateLead`, formData)
          .then(response => {
            if (payload.action === 'skip') {
              if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
                const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))
                if (stateCustom.length > 0) {
                  if (!stateCustom.includes(payload.sw453s01)) {
                    stateCustom.push(payload.sw453s01)
                  }

                  localStorage.setItem(state.historyKeyStorage, JSON.stringify(stateCustom))
                }
              } else {
                const oStatesHistory = []

                oStatesHistory.push(payload.sw453s01)

                localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
              }
            }
            resolve(response.data)
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao fazer a ação na Lead')))
            }
          })
      })

      return request
    },

    async saveLead({ state, dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'leads/importacao/saveLead', { root: true })

      return new Promise((resolve, reject) => {
        dispatch('getFormData').then(formData => {
          formData.append('sw453s01', btoa(state.infoLead.value.sw453s01) || '')
          formData.append('sw453s04', state.form_data.value.sw453s04 || '')
          formData.append('sw453s05', state.form_data.value.sw453s05 || '')
          formData.append('sw453s06', state.form_data.value.sw453s06 || '')
          formData.append('sw453s07', state.form_data.value.sw453s07 || '')

          api.post(`${apiConfig.url_base_api}leads/importacao/saveLead`, formData)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao gravar a Lead')))
              }
            })
        })
      })
    },

    async updateBSM({ state, commit, dispatch }) {
      return new Promise(resolve => {
        const id = btoa(state.infoLead.value.sw453s01) || ''
        dispatch('getFormData').then(formData => {
          api.post(`${apiConfig.url_base_api}leads/importacao/getBsmData/${id}`, formData)
            .then(response => {
              commit('setListingsBSM', response.data.listingsBSM)
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                resolve(new Error(i18n.t('Problema ao carregar BSM')))
              }
            })
        })
      })
    },

    async getMarketStudy({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const id = btoa(state.infoLead.value.sw453s01) || ''
        dispatch('getFormData').then(formData => {
          api.post(`${apiConfig.url_base_api}leads/importacao/getMarketResearchUrl/${id}`, formData)
            .then(response => {
              try {
                if (checkNested(response, 'data.casafariUrl')) {
                  resolve(response.data.casafariUrl)
                } else {
                  reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
                }
              } catch (error) {
                if ((error.response !== undefined) && (error.response.status !== undefined)) {
                  reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
                }
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o estudo do mercado')))
              }
            })
        })
      })
    },

    getFormData({ state }) {
      return new Promise(resolve => {
        const formData = new FormData()
        formData.append('sw453s10', state.selectedType.value.id)
        formData.append('sw453s11', Number(state.selectedType.value.show_cl_tipologia) === 1 ? state.selectedTypology.value.sw005s01 : 0)
        formData.append('sw453s12', state.infoLead.value.sw453s12 || '')
        formData.append('sw453s13', state.infoLead.value.sw453s13 || '')
        formData.append('sw453s14', state.infoLead.value.sw453s14 || '')
        formData.append('sw453s17', state.infoLead.value.sw453s17 || '')
        formData.append('sw453s18', state.infoLead.value.sw453s18 || '')
        resolve(formData)
      })
    },

    resetListviewData({ commit }) {
      commit('setListviewData', [])
      commit('setTotalRows', 0)
      commit('setLoadingListview', true)
      commit('setListviewInit', 1)
      commit('setCurrentPage', 1)
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('loadLeadsImport')
    },

    nextCurrentPage({ state, commit }) {
      commit('setCurrentPage', (state.currentPage.value + 1))
    },

    updateFieldValue({ state, commit }, payload) {
      if (Array.isArray(payload)) {
        payload.forEach(field => {
          commit('setUpdateFormDataField', field)
        })
      } else {
        commit('setUpdateFormDataField', payload)
      }
      commit('setUpdateFormData', state.form_data.value)
    },
  },
}
