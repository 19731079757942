import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Listagem
  listviewData: ref([]),
  init: ref(1),
  loading: ref(true),
  perPage: ref(15),
  totalRows: ref(0),
  currentPage: ref(1),
  txtLoading: i18n.t('A carregar registos ...'),
  txtDefault: i18n.t('Não existe nenhum seguimento'),
  txtNotFind: i18n.t('Não encontrou nenhum resultado'),
  form_data: ref({}),
  infoLead: ref({}),
  listingsBSM: ref([]),
  urlImgListing: ref(''),
  urlImgMarketListing: ref(''),
  historyKeyStorage: 'sw453_skip_leads',
  totalLeads: ref(0),
  types: ref([]),
  typologies: ref([]),
  selectedType: ref({ }),
  selectedTypology: ref({ }),
  listingLeadsCount: ref(0),
})

export default defaultState
