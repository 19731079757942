<template>
  <div>

    <b-row>
      <b-col md="6">
        <app-collapse
          type="margin"
          class="mb-1"
          :style="[{ 'margin-left': '-14px' }]"
        >
          <app-collapse-item
            ref="blockMoreActions"
            :title="$t('Mais ações')"
          >

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click.stop.prevent="updateLead(infoLead.sw453s01, 'skip')"
            >
              {{ $t('Preencher mais tarde') }}
            </b-button>

            <b-button
              v-if="typeof infoLead.seguimento !== 'undefined' && infoLead.seguimento !== null && infoLead.seguimento !== ''"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click.stop.prevent="viewSeguimento(infoLead.sw453s01, infoLead.seguimento[0])"
            >
              {{ $t('Ver seguimento') }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click.stop.prevent="createNewSeguimento(infoLead.sw453s01)"
            >
              {{ $t('Inserir seguimento') }}
            </b-button>

            <b-button
              v-if="typeof infoLead.note !== 'undefined' && infoLead.note !== null && infoLead.note !== ''"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click.stop.prevent="createNewNote(infoLead.sw453s01, infoLead.note)"
            >
              {{ $t('Ver nota') }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click.stop.prevent="createNewNote(infoLead.sw453s01, '')"
            >
              {{ $t('Inserir nota') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              block
              @click.stop.prevent="updateLead(infoLead.sw453s01, 'delete')"
            >
              {{ $t('Eliminar') }}
            </b-button>

          </app-collapse-item>
        </app-collapse>

      </b-col>
    </b-row>

    <b-form
      ref="formSaveLead"
      @submit.prevent
    >
      <validation-observer
        ref="formSaveLeadValidator"
        #default="{}"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('Nome') | lowerBold"
            >
              <b-form-group
                :label="$t('Nome')"
              >
                <b-form-input
                  v-model.lazy="form_data.sw453s04"
                  placeholder=""
                  autocomplete="off"
                  :class="errors.length > 0 ? 'is-invalid':null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('Apelido') | lowerBold"
            >
              <b-form-group
                :label="$t('Apelido')"
              >
                <b-form-input
                  v-model.lazy="form_data.sw453s05"
                  placeholder=""
                  autocomplete="off"
                  :class="errors.length > 0 ? 'is-invalid':null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              :rules="{ numberVueTelInput: numberPhoneValidate }"
              mode="eager"
              :name="$t('Telemóvel') | lowerBold"
            >
              <b-form-group
                :label="$t('Telemóvel')"
              >
                <vue-tel-input
                  ref="sw453s06"
                  v-model="form_data.sw453s06"
                  class="form-control"
                  inputmode="tel"
                  :mode="configPhone.mode"
                  :valid-characters-only="configPhone.validCharactersOnly"
                  :auto-format="configPhone.autoFormat"
                  :default-country="configPhone.defaultCountry"
                  :auto-default-country="configPhone.autoDefaultCountry"
                  :input-options="configPhone.inputOptions"
                  :dropdown-options="configPhone.dropdownOptions"
                  :class="errors.length > 0 ? 'is-invalid':null"
                  @input="checkValidateNumberPhone"
                  @open="onDropdownOpenTelInput"
                  @keydown.native.tab.prevent="$event.target.blur()"
                  @blur.native.capture.prevent="inputValidateNumberPhone($refs.sw453s06)"
                >
                  <template slot="arrow-icon">
                    {{ '\xa0' }}
                  </template>
                  <template slot="icon-right">
                    {{ '\xa0' }}
                  </template>
                </vue-tel-input>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              rules="email"
              :name="$t('Email') | lowerBold"
            >
              <b-form-group
                :label="$t('Email')"
              >
                <b-form-input
                  ref="email"
                  v-model="form_data.sw453s07"
                  placeholder=""
                  autocomplete="off"
                  inputmode="email"
                  :class="errors.length > 0 ? 'is-invalid':null"
                  @keydown.native.tab.prevent="$event.target.blur()"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <h5 class="mt-1">
              {{ $t('Dados da qualificação') }}
            </h5>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-media no-body>
                <b-media-aside>
                  <b-link @click.stop.prevent="openViewListing({ id: infoLead.sw012v01 })">
                    <b-img
                      :src="infoLead.imagem"
                      height="180"
                    />
                  </b-link>
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>

    <b-row>
      <b-col md="6">
        <h6
          v-if="typeof infoLead.PID !== 'undefined' && infoLead.PID !== null"
          class="item-name mt-0 mb-75"
        >
          {{ getTxt(infoLead.PID) }}
        </h6>
        <h6
          v-if="typeof infoLead.tipoImovel !== 'undefined' && infoLead.tipoImovel !== null"
          class="item-name mt-0 mb-75"
        >
          {{ infoLead.sw018v02 }}
        </h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          :label="$t('Tipo')"
        >
          <v-select
            v-model="selectedType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="Object.values(types)"
            label="desc"
            item-text="desc"
            item-value="id"
            append-to-body
            :calculate-position="withPopper"
            @input="updateBSM"
          >
            <template #option="{ desc }">
              {{ desc }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          v-if="Number(selectedType.show_cl_tipologia) === 1"
          :label="$t('Tipologia')"
        >
          <v-select
            v-model="selectedTypology"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="Object.values(typologies)"
            label="sw005s02"
            item-text="sw005s02"
            item-value="sw005s01"
            append-to-body
            :calculate-position="withPopper"
            @input="updateBSM"
          >
            <template #option="{ sw005s02 }">
              {{ sw005s02 }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <BlockLocalizationPt
          ref="blockLocalization"
          :simple-form="true"
          :init-data-form="{
            distrito: infoLead.sw453s12,
            concelho: infoLead.sw453s13,
            freguesia: infoLead.sw453s14,
          }"
          @change="onLocalizationChange"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group
          :label="$t('Preço mínimo')"
        >
          <b-input-group
            :prepend="symbolMoney"
            class="input-group-merge"
          >
            <b-form-input
              v-model.lazy="infoLead.sw453s17"
              v-currency="configMoney"
              placeholder=""
              autocomplete="off"
              @change="updateBSM"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group
          :label="$t('Preço máximo')"
        >
          <b-input-group
            :prepend="symbolMoney"
            class="input-group-merge"
          >
            <b-form-input
              v-model="infoLead.sw453s18"
              v-currency="configMoney"
              placeholder=""
              autocomplete="off"
              @change="updateBSM"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <app-collapse
          type="margin"
          class="mb-1"
          :style="[{ 'margin-left': '-14px' }]"
        >
          <app-collapse-item
            ref="blockListingsBSM"
            :title="$t('Imóveis Zome')"
          >
            <template v-if="listingsBSM.length > 0">
              <b-row>
                <b-col cols="12">
                  <preview-item
                    v-for="(listing, index) in listingsBSM"
                    :key="`listing-id-${index}`"
                    class="mb-1"
                    :listing="listing"
                    :index="index"
                    :extra-class="['employee-task']"
                    :show-link-detail="true"
                    :open-view-listing="openViewListing"
                  />
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row>
                <b-col cols="12">
                  <b-alert
                    show
                    variant="danger"
                  >
                    <div class="alert-body">
                      <span class="ml-25">{{
                        $t('Nenhum resultado encontrado')
                      }}. {{ $t('Para obter resultados deve refinar os parâmetros') }}.</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
            </template>

          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <app-collapse
          type="margin"
          class="mb-1"
          :style="[{ 'margin-left': '-14px' }]"
        >
          <app-collapse-item
            ref="blockListingsExternal"
            :title="$t('Imóveis do mercado')"
          >

            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-media no-body>
                    <b-media-aside>
                      <b-link @click.stop.prevent="openViewMarketResearch(infoLead.sw012v01)">
                        <b-img
                          :src="urlImgMarketListing+'listing_1.jpg'"
                          height="250"
                        />
                      </b-link>
                    </b-media-aside>
                  </b-media>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-media no-body>
                    <b-media-aside>
                      <b-link @click.stop.prevent="openViewMarketResearch(infoLead.sw012v01)">
                        <b-img
                          :src="urlImgMarketListing+'listing_2.jpg'"
                          height="250"
                        />
                      </b-link>
                    </b-media-aside>
                  </b-media>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-media no-body>
                    <b-media-aside>
                      <b-link @click.stop.prevent="openViewMarketResearch(infoLead.sw012v01)">
                        <b-img
                          :src="urlImgMarketListing+'listing_3.jpg'"
                          height="250"
                        />
                      </b-link>
                    </b-media-aside>
                  </b-media>
                </b-form-group>
              </b-col>
            </b-row>

          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BFormInput, BForm, BButton, BMedia, BMediaAside, BImg, BLink, BAlert, BInputGroup,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { eventsCustomSelects, sizeIconsAction, VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import { formatPrice } from '@core-custom/utils/ui'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { configPhone } from '@core-custom/utils/phone'
import { numberVueTelInput } from '@validations-custom'
import PreviewItem from '@/views/modules/components/blockListingSimple.vue'
import BlockLocalizationPt from '@/views/modules/components/localization/country/form/pt.vue'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { CurrencyDirective } from 'vue-currency-input'
import vSelect from 'vue-select'
import { mixFormGeral } from './mixins'

const oVueTelInput = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
  import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BButton,
    BMedia,
    BMediaAside,
    BImg,
    BLink,
    BAlert,
    BInputGroup,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    VueTelInput: oVueTelInput,
    PreviewItem,
    BlockLocalizationPt,
  },
  directives: {
    mask,
    Ripple,
    currency: CurrencyDirective,
  },
  mixins: [sizeIconsAction, VeeValidateLoadLocale, mixFormGeral, eventsCustomSelects],
  props: {
    updateLead: {
      type: Function,
      require: true,
      default: null,
    },
    openViewListing: {
      type: Function,
      require: true,
      default: null,
    },
    createNewSeguimento: {
      type: Function,
      require: true,
      default: null,
    },
    viewSeguimento: {
      type: Function,
      require: true,
      default: null,
    },
    createNewNote: {
      type: Function,
      require: true,
      default: null,
    },
    openViewMarketResearch: {
      type: Function,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      configMoney,
      symbolMoney,
      visibleModal: true,
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('sw453', ['form_data', 'infoLead', 'listingsBSM', 'urlImgListing', 'urlImgMarketListing', 'types', 'typologies']),
    selectedType: {
      get() {
        return this.$store.getters['sw453/selectedType']
      },
      set(newValue) {
        return this.$store.commit('sw453/setSelectedType', newValue)
      },
    },
    selectedTypology: {
      get() {
        return this.$store.getters['sw453/selectedTypology']
      },
      set(newValue) {
        return this.$store.commit('sw453/setSelectedTypology', newValue)
      },
    },
  },
  mounted() {
    if (this.form_data?.sw453s06 !== undefined && this.form_data.sw453s06 !== '') {
      const sFormDataSw453s06 = this.form_data.sw453s06.substring(0, 3)
      if (sFormDataSw453s06.includes('00')) {
        this.$store.dispatch('sw453/updateFieldValue', {
          field: 'sw453s06',
          value: this.form_data.sw453s06.replace(/^00/, '+'),
        })
      }
    }
  },
  setup() {
    return {
      configPhone,
      numberVueTelInput,
    }
  },
  methods: {
    async updateBSM() {
      await this.$store.dispatch('sw453/updateBSM')
    },
    async onLocalizationChange(payload) {
      this.$store.commit('sw453/setLocalization', { sw453s12: payload.distrito, sw453s13: payload.concelho, sw453s14: payload.freguesia })
      await this.updateBSM()
    },
  },
}
</script>
